<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">Siddiqi, A., Irwin, L. G., & Hertzman, C. (2007). The total environment assessment model of early child development. <em>Vancouver: Organización Mundial de la Salud.</em></li>
        <li class="pb-3"><em>Our Stories: First Peoples in Canada</em>. (n.d.). Centennial College.</li>
        <li class="pb-3"><em>What I wish my professors knew about me…</em> (2017, March 22). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=g8h1cODvA9g" target="_blank">https://www.youtube.com/watch?v=g8h1cODvA9g</a></li>
        <li class="pb-3"><em>Digital Stories</em>. (2018). Proclaiming Our Roots. <a href="https://www.proclaimingourroots.com/partners" target="_blank">https://www.proclaimingourroots.com/partners</a></li>
        <li class="pb-3"><em>2 Spirits in Motion</em>. (2022). 2 SPIRITS IN MOTION SOCIETY. <a href="https://2spiritsinmotion.com/" target="_blank">https://2spiritsinmotion.com/</a></li>
        <li class="pb-3"><em>2-Spirited People of the 1st Nations</em>. (2022). 2-Spirited People of the 1st Nations. <a href="https://www.2spirits.org/" target="_blank">https://www.2spirits.org/</a></li>
        <li class="pb-3">Iowa State University. (2021, October 4). <em>Reflect on Your Sense of Belonging Practices</em>. Iowa State University Center for Excellence in Learning and Teaching. <br><a href="https://www.celt.iastate.edu/wp-content/uploads/2021/07/sense-of-belonging-practices.pdf" target="_blank">www.celt.iastate.edu/wp-content/uploads/2021/07/sense-of-belonging-practices.pdf</a></li>
        <li class="pb-3"><em>Fostering College Students’ Sense of Belonging Amidst COVID-19 | SSRI COVID-19 Resources</em>. (n.d.). Penn State Social Science Research Institute.<br> <a href="https://covid19.ssri.psu.edu/articles/fostering-college-students-sense-belonging-amidst-covid-19" target="_blank">covid19.ssri.psu.edu/articles/fostering-college-students-sense-belonging-amidst-covid-19</a></li>
        <li class="pb-3"><em>On Diversity: Access Ain’t Inclusion | Anthony Jack | TEDxCambridge</em>. (2019, June 13). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=j7w2Gv7ueOc" target="_blank">www.youtube.com/watch?v=j7w2Gv7ueOc</a></li>
        <li class="pb-3">Ezekiel, F. (2021). Supporting Mental Health and Learning among Students with Marginalized Identities. <em>Centennial College Student Success Summit</em>.</li>
        <li class="pb-3"><em>Students Learn A Powerful Lesson About Privilege</em>. (2014, December 9). [Video]. YouTube.  <br><a href="https://www.youtube.com/watch?v=2KlmvmuxzYE" target="_blank">www.youtube.com/watch?v=2KlmvmuxzYE</a></li>
        <li class="pb-3"><em>Social Model Animation</em>. (2013, July 17). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=4liF-zuwGxE" target="_blank">www.youtube.com/watch?v=4liF-zuwGxE</a></li>
        <li class="pb-3"><em>How to understand power - Eric Liu</em>. (2014, November 4). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=c_Eutci7ack&t=109s" target="_blank">www.youtube.com/watch?v=c_Eutci7ack&t=109s</a></li>
        <li class="pb-3"><em>Privilege vs. Oppression</em>. (2018, August 20). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=ZOOUmOzUeTY" target="_blank">www.youtube.com/watch?v=ZOOUmOzUeTY</a></li>
        <li class="pb-3"><em>What is intersectionality?</em> (2018, April 22). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=O1islM0ytkE" target="_blank">www.youtube.com/watch?v=O1islM0ytkE</a></li>
        <li class="pb-3"><em>The urgency of intersectionality | Kimberlé Crenshaw</em>. (2016, December 7). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=akOe5-UsQ2o" target="_blank">www.youtube.com/watch?v=akOe5-UsQ2o</a></li>
        <li class="pb-3"><em>Privilege is power. How you can use it to do some good!</em> (2021, March 12). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=1Ea5WudpEAs" target="_blank">www.youtube.com/watch?v=1Ea5WudpEAs</a></li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
